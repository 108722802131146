import { Link } from 'react-router-dom';
import "./footer.scss";
import { DownloadButtons } from './download-buttons';

export function Footer() {
  return (
    <footer className="footer"> 
      <Copyright />
    </footer>
  );
}

const Copyright = () => {
  const startYear = 2024;
  const currentYear = new Date().getFullYear();
  const yearLabel = startYear === currentYear ? currentYear : `${startYear}-${currentYear}`;

  return (
    <div className="columns footer-copy">
      <div className="column footer-copy-column1">
        <span>©{yearLabel} BabyTrove, Nimpx Ltd</span>
        <div className="social-links">
          <a href="https://www.facebook.com/babytroveapp" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
          </a>

          <a href="https://www.instagram.com/babytroveapp" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>

          <a href="https://www.threads.net/@babytroveapp" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-threads"></i>
          </a>

          {/* <a href="https://www.youtube.com/channel/UCwji8DwpaB5RRkC4GiqBibw" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube"></i>
          </a>           */}
        </div>
      </div>
      <div className="column footer-copy-column2">
        <div className="app-links">
          {/* <span>Download the app:</span> */}
          <DownloadButtons />
        </div>
        {/* <div className="app-links">
          <Link to="/privacy-policy"><i className="fab fa-apple"></i>&nbsp;Download for iOS</Link>
          <Link to="/privacy-policy"><i className="fab fa-android"></i>&nbsp;Download for Android</Link>
        </div> */}
        <div>
          <Link to="/privacy-policy">Privacy Policy</Link>
          &nbsp;|&nbsp;
          <Link to="/terms-and-conditions">Terms and Conditions</Link>      
        </div>
      </div>
    </div>      
  )
};