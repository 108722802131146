import { ContentLogo } from '../components/content-logo';

export function NoMatch() {
  return (
    <div className="section container" style={{ textAlign: "center" }}>
      <ContentLogo />

      <h1 className="title">Oh no!</h1>      

      <div className="content">
        <p>It looks like you've hit a page that doesn't exist.</p>
        <p>Go back to the <a href="/">homepage</a></p>
      </div>
    </div>
  );
}