export class PlatformUtils {
  static select = (p: {
    ios?: any,
    android?: any,
    default?: undefined
  }) => {
    const os = this.OS();
    if (os === "android") {
      return p.android;
    } else if (os === "ios") {
      return p.ios;
    }

    return p.default;
  }

  static OS = (): "android" | "ios" | "default" => {
    var userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        return "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "ios";
    }

    return "default";
  }
}