import React from 'react';
import { ContentLogo } from '../components/content-logo';
import { DownloadButtons } from '../components/download-buttons';

import "./download.scss";
import { AppConfig } from '../config';
import { PlatformUtils } from '../utils/os';

export class Download extends React.PureComponent<any, any> {

  constructor(props: any) {
    super(props);

    this.state = {
      os: ""
    };
  }

  render() {
    return (
      <div className="section container download-app" style={{ textAlign: "center" }}>
        <ContentLogo />

        <h1 className="title">Get the mobile app</h1>      

        <div className="content">
          <p>Download the app by tapping one of buttons below.</p>
          <p>You can also scan the QR code with your device if you are reading this on a computer.</p>        

          <DownloadButtons showQRCode={true} />
        </div>
      </div>
    );
  }

  componentDidMount(): void {
    document.title = `Download the app | ${AppConfig.title}`;

    const os = PlatformUtils.OS();
    this.setState({ os });

    if (os === "android") {
      window.location.href = AppConfig.android_download;
      return;
    } else if (os === "ios") {
      window.location.href = AppConfig.ios_download;
      return;
    }
  }
}