import React from 'react';
import './assets/css/styles.css';
import './assets/css/styles.scss';

import { Route, Routes } from 'react-router-dom';
import { Home } from './pages/home';
import { Terms } from './pages/terms';
import { Privacy } from './pages/privacy';
import { NoMatch } from './pages/404';
// import { Header } from './components/header';
import { Footer } from './components/footer';
import { Download } from './pages/download';

function App() {
  return (
    <div className="app">
      {/* <Header /> */}

      <Routes>
        <Route path="/" element={<Home />} />        

        <Route path="download" element={<Download />} />
        
        <Route path="terms-and-conditions" element={<Terms />} />
        <Route path="privacy-policy" element={<Privacy />} />        

        <Route path="*" element={<NoMatch />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;






