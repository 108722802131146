
import React, { useEffect } from 'react';
// import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { DownloadButtons } from '../components/download-buttons';
import { useLocation } from 'react-router-dom';

import './home.scss';
import { AppConfig } from '../config';

const ScrollToSection = (props: { children: any }) => {
  const location = useLocation();
  useEffect(() => {
    const url = new URL(window.location.href);
    const hash = url.hash;
    if (hash) {
      const element = document.querySelector(hash);
      const top = element?.getBoundingClientRect().top;
      window.scrollTo({ top, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
    {props.children}
    </>
  );
};

export function Home() {
  useEffect(() => {
    document.title = AppConfig.title;
  }, []);
  return (
    <ScrollToSection>
      <HeroSection />
      <HowItWorksSection />
      {/* <FeaturesSection /> */}
    </ScrollToSection>
  );
}

const HeroSection = () => {
  return (
    <section className="hero is-light hero-top">
      <div className="hero-body container">
        <div className="columns">
          <div className="column is-7-desktop hero-info">
            <img src="/img/logo.png" alt="Logo" className="logo" style={{ borderRadius: 8 }} />

            <p className="title">BabyTrove - find baby names app</p>
            <p className="subtitle">Find the name of your baby with our easy to use app</p>
            <p>Choose from thousands of names in multiple languages and collaborate with your family and friends in real-time.</p>

            <DownloadButtons />
          </div>
          <div className="column is-5-desktop hero-screenshots">
            <img src="/img/screenshots/ios1.png" alt="Hero" />
          </div>
        </div>
      </div>
    </section>
  );
}

const HowItWorksSection = () => {

  const Step = (props: {
    step: number,
    title: string,
    children: string
  }) => {
    return (
      <div className="feature">
        <div>
          <div className="circle"><span>{props.step}</span></div>
        </div>
        <div>
          <p className="feature-title">{props.title}</p>
          <p className="feature-description">{props.children}</p>
        </div>
      </div>
    )
  };

  return (
    <section className="hero" id="home-how-it-works">
      <div className="hero-body container">
        <p className="title">How does it work?</p>
        {/* <p className="subtitle">Process subtitle</p> */}

        <div className="columns is-multiline">
          <div className="column is-full-mobile is-4-desktop">
            <Step step={1} title="Add a baby">
              Start by adding a baby to your list
            </Step>
          </div>

          <div className="column is-full-mobile is-4-desktop">
            <Step step={2} title="Name languages">
              Choose the languages you want to use to find names
            </Step>
          </div>

          <div className="column is-full-mobile is-4-desktop">
            <Step step={3} title="Pick your favourite names">
              Browse the names we suggest and pick your favourites
            </Step>
          </div>

          <div className="column is-full-mobile is-4-desktop">
            <Step step={4} title="Build your baby's name">
              Once you have picked your favourite names, you can combine them to create a full name
            </Step>
          </div>

          <div className="column is-full-mobile is-4-desktop">
            <Step step={5} title="Rate">
              Invite your family and friends to vote on the names you have chosen
            </Step>
          </div>

          <div className="column is-full-mobile is-4-desktop">
            <Step step={6} title="Choose your favourite name">
              Choose the name that everyone loves the most
            </Step>
          </div>
        </div>
      </div>
    </section>
  );
};


// const FeaturesSection = () => {

//   const Feature = (props: {
//     icon: string,
//     title: string,
//     children: string
//   }) => {
//     return (
//       <div className="feature">
//         <i className={props.icon}></i>
//         <div>
//           <p className="feature-title">{props.title}</p>
//           <p className="feature-description">{props.children}</p>
//         </div>
//       </div>
//     )
//   };

//   return (
//     <section className="hero" id="home-features">
//       <div className="hero-body container">
//         <p className="title">Features</p>
//         <div className="columns is-multiline">
//           <div className="column is-full-mobile is-one-third-desktop">
//             <Feature 
//               icon="fa-solid fa-circle-check"
//               title="Multiple languages"
//             >Home feature description</Feature>
//           </div>

//           <div className="column is-full-mobile is-one-third-desktop">
//             <Feature 
//               icon="fa-solid fa-circle-check"
//               title="Collaborate in real-time"
//             >
//               Home feature description
//             </Feature>
//           </div>

//           <div className="column is-full-mobile is-one-third-desktop">
//             <Feature 
//               icon="fa-solid fa-circle-check"
//               title="Feature 3"
//             >
//               Home feature description
//             </Feature>
//           </div>

//           <div className="column is-full-mobile is-one-third-desktop">
//             <Feature icon="fa-solid fa-circle-check" title="Feature 4">
//               Home feature description
//             </Feature>
//           </div>

//           <div className="column is-full-mobile is-one-third-desktop">
//             <Feature icon="fa-solid fa-circle-check" title="Feature 5">
//               Home feature description
//             </Feature>
//           </div>

//           <div className="column is-full-mobile is-one-third-desktop">
//             <Feature icon="fa-solid fa-circle-check" title="Feature 6">
//               Home feature description
//             </Feature>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };
