import { AppConfig } from "../config";
import { PlatformUtils } from "../utils/os";
import "./download-buttons.scss";

export const DownloadButtons = (props: {
  showQRCode?: boolean;
  filterOS?: boolean;
}) => {
  const showQRCode = props.showQRCode ?? false;
  const filterOS = props.filterOS ?? false;

  const os = filterOS ? PlatformUtils.OS() : "default";

  return (
    <div className="app-download-buttons" data-os={os}>
      <div className="app-download-buttons-outter">
        <div className="app-download-buttons-inner">
          {["ios", "default"].indexOf(os) >= 0 &&
            <a href={AppConfig.ios_download} target="_blank" rel="noreferrer">
              <img src="/img/app-store.webp" alt="Download on the App Store" />
            </a>
          }
          {["android", "default"].indexOf(os) >= 0 &&
            <a href={AppConfig.android_download} target="_blank" rel="noreferrer">
              <img src="/img/android-download.png" alt="Get it on Google Play" />
            </a>
          }
        </div>
        {showQRCode &&
          <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", width: 110 }}>
            <img src="/img/download-qr-code.png" alt="Scan me with your phone" />
            <span style={{ fontSize: 10, fontWeight: 600 }}>Scan with your phone</span>
          </div>
        }
      </div>
    </div>
  );
}